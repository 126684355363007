import React, { FC, memo } from 'react';
import { BoxProps } from '@material-ui/core/Box';

import { Group as UIGroup, Title, Content } from './group.styles';
import { FieldsGroup } from './group.types';

type CustomProps = Omit<FieldsGroup, 'fields'>;

type Props = BoxProps & CustomProps;

export const Group: FC<Props> = memo(
  ({ title, children, description, notice }) => (
    <UIGroup>
      {title && <Title variant="body2">{title}</Title>}
      {description && description}
      <Content>{children}</Content>
      {notice && notice}
    </UIGroup>
  )
);
