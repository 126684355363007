import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import MUIFormControlLabel, {
  FormControlLabelProps,
} from '@material-ui/core/FormControlLabel';
import { Palette } from '@material-ui/core/styles/createPalette';
import MUIFormControl, {
  FormControlTypeMap,
} from '@material-ui/core/FormControl';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';

import { Colors, WithMyTheme } from '@keaze/web/theme';

interface IconProps extends WithMyTheme {
  $checked?: boolean;
  $yes: boolean;
}

interface CustomFormControlLabelProps extends WithMyTheme {
  $error: boolean;
  $checked?: boolean;
}

type CheckedIconStylesArgs = {
  palette: Palette;
  colors: Colors;
  yes: boolean;
};

const checkedIconStyles = ({ palette, colors, yes }: CheckedIconStylesArgs) => `
  svg {
    opacity: 1;
    fill: ${yes ? colors.greens.green0 : palette.secondary.main};
  }
`;

const labelCheckedStyles = (palette: Palette) => `
  background-color: ${palette.common.white};
  border-color: ${palette.primary.main};
`;

const labelErrorStyles = (palette: Palette) => `
  background-color: ${palette.error.light};
  border-color: ${palette.secondary.main};

  &:hover {
    border-color: ${palette.secondary.main};
  }
`;

export const Radio = styled(Box)`
  ${({ theme: { breakpoints } }) => `
    margin-right: 10px;
    width: 100%;

    &:last-child {
      margin-right: 0;
    }

    ${breakpoints.up('sm')} {
      width: auto;
    }
  `}
`;

export const Icon = styled(Box)<IconProps>`
  ${({
    theme: {
      palette,
      colors,
      transitions: { duration, easing },
      breakpoints,
    },
    $checked,
    $yes,
  }) => `
    position: absolute;
    top: 50%;
    left: calc(50% - 22px);
    transform: translate(-50%, -50%);
    pointer-events: none;

    svg {
      opacity: 0.5;
      fill: ${colors.greys.greys17};
      transition: ${duration.short}ms ${easing.easeInOut};
    }

    ${breakpoints.up('sm')} {
      left: 15px;
      transform: translateY(-50%);
    }

    ${$checked ? checkedIconStyles({ palette, colors, yes: $yes }) : ''};
  `}
`;

export const FormControlLabel = styled(MUIFormControlLabel)<
  FormControlLabelProps & CustomFormControlLabelProps
>`
  ${({
    theme: {
      palette,
      colors,
      transitions: { duration, easing },
      breakpoints,
    },
    $checked,
    $error,
  }) => `
    width: 100%;
    margin: 0;
    display: flex;
    justify-content: center;
    padding: 10px 0 10px 10px;
    background-color: ${colors.greys.greys3};
    border: 1px solid transparent;
    transition: ${duration.short}ms ${easing.easeInOut};

    .MuiButtonBase-root {
      display: none;
    }

    .MuiFormControlLabel-label {
      font-weight: 500;
    }

    &:hover {
      border-color: ${palette.primary.main};
    }

    ${breakpoints.up('sm')} {
      width: 95px;
      justify-content: flex-start;
      padding: 10px 0 10px 40px;
    }

    ${$checked ? labelCheckedStyles(palette) : ''};
    ${$error ? labelErrorStyles(palette) : ''};
  `}
`;

export const FormControl: OverridableComponent<FormControlTypeMap> = styled(
  MUIFormControl
)`
  ${({ theme: { breakpoints } }) => `
    width: 100%;

    ${breakpoints.up('sm')} {
      width: auto;
    }
  `}
`;

export const ErrorMessage = styled(Typography)`
  ${({ theme }) => `
    margin-top: ${theme.spacing(1)}px;
    color: ${theme.palette.error.main};
  `}
`;
