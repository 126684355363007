import { isEmpty, required } from '@keaze/web/common/validators';
import { GenerateValidate } from './validate.types';

export const generateValidate: GenerateValidate = (
  value,
  { required: isRequired, valid, important }
) => {
  const isValueEmpty = isEmpty(value);
  const isImportant = value === false && important;

  if ((isRequired && isValueEmpty) || isImportant) {
    return required(value);
  }

  if (valid) {
    return valid(value);
  }

  return null;
};
