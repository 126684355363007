import styled from 'styled-components';
import {
  Box as MUIBox,
  InputAdornment as MUIInputAdornment,
} from '@material-ui/core';

import { TextField } from '@keaze/web/components/textField';
import { WithMyTheme } from '@keaze/web/theme';

interface StyledTextFieldProps extends WithMyTheme {
  $isValue?: boolean;
}

export const Field: any = styled(MUIBox)`
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const InputAdornment = styled(MUIInputAdornment)`
  white-space: normal;
`;

export const StyledTextField = styled(TextField)<StyledTextFieldProps>`
  .MuiInputBase-root {
    input[type='number'] {
      -moz-appearance: textfield;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }

  .MuiInputBase-adornedStart {
    padding-left: 20px;

    .MuiInputAdornment-positionStart {
      margin-right: 2px;

      & > * {
        font-weight: 500;
        color: ${({ theme, $isValue = false }) =>
          $isValue ? theme.palette.text.primary : theme.colors.greys.greys25};
      }
    }

    .MuiInputBase-input {
      padding-left: 0;
    }
  }
`;
