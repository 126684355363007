import { MutableRefObject } from 'react';
import { ValidationErrors } from 'final-form';

import { RequestErrors } from '@keaze/web/common/interfaces';

type FormValidateArgs<T> = {
  values: T;
  prevFormValues: MutableRefObject<T>;
  errors: RequestErrors;
};

export const formValidate = <T>({
  values,
  prevFormValues,
  errors,
}: FormValidateArgs<T>): ValidationErrors => {
  const customErrors = Object.keys(errors).reduce((acc, key) => {
    if (values[key] === prevFormValues.current[key]) {
      return {
        ...acc,
        [key]: errors[key],
      };
    }
    return acc;
  }, {});

  prevFormValues.current = values;

  return customErrors;
};
