import React, { FC, memo, useEffect, useRef } from 'react';
import { Field as FField } from 'react-final-form';

import {
  CheckboxField,
  CheckboxProps,
  Field,
  FieldProps,
  Components,
  Group,
  FormFieldRefs,
} from '@keaze/web/utils/form';
import { FieldGroup } from '@keaze/web/utils/form/fields/group';
import { generateValidate } from '../helpers/validate';
import { Radio } from '../fields/radio';
import { GroupsProps } from './groups.types';
import { RadioFieldGroup } from './groups.styles';
import { Button, Box } from '@material-ui/core';

type RenderCheckbox = (
  checkbox: CheckboxProps,
  getFieldRef: GetFieldRef
) => JSX.Element;

type GetFieldRef = (name: string, ref: HTMLDivElement | null) => void;

type RenderField = (
  fieldProps: FieldProps,
  getFieldRef: GetFieldRef
) => JSX.Element | null;

export const renderCheckbox: RenderCheckbox = (checkbox, getFieldRef) => {
  const { name, label, validate = { required: false } } = checkbox;

  return (
    <FField
      key={name}
      name={name}
      label={label}
      type="checkbox"
      validate={(value) => generateValidate(value, validate)}
      component={CheckboxField}
      CheckboxProps={checkbox}
      getRef={getFieldRef}
    />
  );
};

export const renderField: RenderField = (fieldProps, getFieldRef) => {
  const {
    name,
    label,
    type,
    checkboxGroupData = [],
    validate = {
      required: true,
    },
    component,
    onClick,
  } = fieldProps;

  if (component === Components.CheckboxGroup) {
    if (checkboxGroupData.length === 0) {
      return null;
    }

    return (
      <FieldGroup
        key={name}
        label={label}
        container
        direction="column"
        alignItems="flex-start"
      >
        {checkboxGroupData.map((checkbox) =>
          renderCheckbox(checkbox, getFieldRef)
        )}
      </FieldGroup>
    );
  }

  if (component === Components.Radio) {
    return (
      <RadioFieldGroup
        key={name}
        label={label}
        container
        getRef={(ref) => getFieldRef(name, ref.current)}
      >
        <FField
          key={`${name} yes`}
          fieldProps={fieldProps}
          name={name}
          label="Yes"
          value="yes"
          type={type}
          validate={(value) => generateValidate(value, validate)}
          component={Radio}
        />
        <FField
          key={`${name} no`}
          fieldProps={fieldProps}
          name={name}
          label="No"
          value="no"
          type={type}
          validate={(value) => generateValidate(value, validate)}
          component={Radio}
        />
      </RadioFieldGroup>
    );
  }

  if (component == Components.Button) {
    return (
      <Box css={{ textAlign: 'right' }} key={name}>
        <Button
          variant={'contained'}
          name={name}
          size={'small'}
          color={'primary'}
          onClick={onClick}
        >
          {label}
        </Button>
      </Box>
    );
  }

  return (
    <FField
      key={name}
      fieldProps={fieldProps}
      allowNull
      name={name}
      label={label}
      type={type}
      parse={(value) => value}
      validate={(value) => generateValidate(value, validate)}
      component={Field}
      getRef={getFieldRef}
    />
  );
};

export const Groups: FC<GroupsProps> = memo(
  ({ fieldsGroups, getFieldRefs }) => {
    const fieldRefs = useRef<FormFieldRefs>({});

    useEffect(() => {
      if (getFieldRefs && Object.keys(fieldRefs.current).length !== 0) {
        getFieldRefs(fieldRefs.current);
      }
    }, [fieldRefs]);

    const getFieldRef: GetFieldRef = (name, ref) => {
      fieldRefs.current = {
        ...fieldRefs.current,
        [name]: ref,
      };
    };

    return (
      <>
        {fieldsGroups.map((group, key) => {
          const { fields, ...restGroup } = group;

          return (
            <Group key={key} {...restGroup}>
              {fields.map((fieldProps) => renderField(fieldProps, getFieldRef))}
            </Group>
          );
        })}
      </>
    );
  }
);
