import styled from 'styled-components';

import { FieldGroup } from '@keaze/web/utils/form/fields/group';

export const RadioFieldGroup = styled(FieldGroup)`
  ${({ theme: { breakpoints } }) => `
    .MuiGrid-root {
      width: 100%;
      flex-wrap: nowrap;
    }

    ${breakpoints.up('sm')} {
      .MuiGrid-root {
        width: auto;
      }
    }
  `}
`;
