import { ElementType, FC } from 'react';

import { Tooltip, TooltipProps } from '@keaze/web/components/tooltip';
import { IconButton } from './tooltipIconButton.styles';

type CustomProps = {
  Icon?: ElementType;
};

type Props = CustomProps & Omit<TooltipProps, 'children'>;

const QuestionMarkIcon = (): JSX.Element => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="20" height="20" rx="2" fill="currentColor" />
    <path
      d="M8 8C8 6.89543 8.89543 6 10 6C11.1046 6 12 6.89543 12 8C12 8.39815 11.8837 8.76913 11.6831 9.08079C11.0854 10.0097 10 10.8954 10 12V12"
      stroke="#F9F8F6"
      strokeWidth="2"
    />
    <rect x="9" y="13" width="2" height="2" fill="#F9F8F6" />
  </svg>
);

export const TooltipIconButton: FC<Props> = ({
  Icon = QuestionMarkIcon,
  ...restProps
}) => (
  <Tooltip {...restProps}>
    <IconButton>
      <Icon />
    </IconButton>
  </Tooltip>
);
