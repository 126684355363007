import { FormField } from './homeOwnershipStatuses.types';

const DEFAULT_STATUSES = {
  [FormField.SellingStatus]: null,
  [FormField.FirstTimeBuyerStatus]: null,
  [FormField.PropertyStatus]: null,
};

type Values = {
  [FormField.HomeOwnershipStatus]: number | null;
  [FormField.SellingStatus]: string | null;
  [FormField.FirstTimeBuyerStatus]: string | null;
  [FormField.PropertyStatus]: string | null;
};

type UseHomeOwnershipStatusesInitialValuesExpected = {
  values: Values;
};

type UseHomeOwnershipStatusesInitialValues = (
  homeOwnershipStatusValue: number | null,
  isOwner: boolean
) => UseHomeOwnershipStatusesInitialValuesExpected;

type GenerateValues = (
  homeOwnershipStatusValue: number | null,
  generatedStatuses: GenerateStatusesExpected
) => Values;

type GenerateStatusesExpected = Record<number, Record<string, string | null>>;

type GenerateStatuses = (isOwner: boolean) => GenerateStatusesExpected;

const generateStatuses: GenerateStatuses = (isOwner) => {
  if (isOwner) {
    return {
      1: DEFAULT_STATUSES,
      2: DEFAULT_STATUSES,
      3: {
        [FormField.FirstTimeBuyerStatus]: null,
        [FormField.PropertyStatus]: null,
        [FormField.SellingStatus]: 'yes',
      },
      4: {
        [FormField.FirstTimeBuyerStatus]: null,
        [FormField.PropertyStatus]: null,
        [FormField.SellingStatus]: 'no',
      },
    };
  }

  return {
    1: {
      [FormField.FirstTimeBuyerStatus]: 'yes',
      [FormField.PropertyStatus]: null,
      [FormField.SellingStatus]: null,
    },
    2: {
      [FormField.FirstTimeBuyerStatus]: 'no',
      [FormField.PropertyStatus]: 'no',
      [FormField.SellingStatus]: null,
    },
    3: {
      [FormField.FirstTimeBuyerStatus]: 'no',
      [FormField.PropertyStatus]: 'yes',
      [FormField.SellingStatus]: 'yes',
    },
    4: {
      [FormField.FirstTimeBuyerStatus]: 'no',
      [FormField.PropertyStatus]: 'yes',
      [FormField.SellingStatus]: 'no',
    },
  };
};

const generateValues: GenerateValues = (
  homeOwnershipStatusValue,
  generatedStatuses
) => {
  if (!homeOwnershipStatusValue) {
    return {
      [FormField.HomeOwnershipStatus]: homeOwnershipStatusValue,
      ...DEFAULT_STATUSES,
    };
  }

  const status = generatedStatuses[homeOwnershipStatusValue];

  return {
    [FormField.HomeOwnershipStatus]: homeOwnershipStatusValue,
    [FormField.SellingStatus]: status[FormField.SellingStatus],
    [FormField.FirstTimeBuyerStatus]: status[FormField.FirstTimeBuyerStatus],
    [FormField.PropertyStatus]: status[FormField.PropertyStatus],
  };
};

export const useHomeOwnershipStatusesInitialValues: UseHomeOwnershipStatusesInitialValues = (
  homeOwnershipStatusValue,
  isOwner
) => {
  const generatedStatuses = generateStatuses(isOwner);

  return {
    values: generateValues(homeOwnershipStatusValue, generatedStatuses),
  };
};
