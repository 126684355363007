import styled from 'styled-components';
import { IconButton as MUIIconButton } from '@material-ui/core';

export const IconButton = styled(MUIIconButton)`
  ${({
    theme: {
      transitions: { easing, duration },
      colors,
    },
  }) => `
    color: ${colors.greys.greys6};
    transition: ${duration.shortest}ms ${easing.easeInOut};
    opacity: 0.25;

    &:hover {
      opacity: 1;
    }
  `}
`;
