import { ChangeEvent, memo } from 'react';
import { FieldRenderProps } from 'react-final-form';

import { Checkbox, CheckboxProps } from '@keaze/web/components/checkbox';

interface Props<T> extends FieldRenderProps<T, HTMLElement> {
  CheckboxProps: CheckboxProps;
}

type OnChange = (e: ChangeEvent<HTMLInputElement>) => void;

const CheckboxFieldInner = <T extends unknown>({
  CheckboxProps: { label },
  input,
  meta: { error, touched },
  getRef,
}: Props<T>): JSX.Element => {
  const commonProps = {
    label,
    error: !!error && !!touched,
    helperText: error,
  };

  const handleChange: OnChange = (e) => {
    input.onChange(e);
    input.onBlur();
  };

  return (
    <Checkbox
      {...input}
      {...commonProps}
      onChange={handleChange}
      getRef={getRef}
    />
  );
};

export const CheckboxField = memo(CheckboxFieldInner);
