import React, {
  FC,
  memo,
  ReactNode,
  RefObject,
  useEffect,
  useRef,
} from 'react';
import Grid, { GridProps } from '@material-ui/core/Grid';

import { Group, Label } from './group.styles';

type CustomProps = {
  label?: ReactNode;
  getRef?: (ref: RefObject<HTMLDivElement>) => void;
};

type Props = CustomProps & GridProps;

export const FieldGroup: FC<Props> = memo(
  ({ children, label, getRef, className, ...gridProps }) => {
    const groupRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (getRef && groupRef.current) {
        getRef(groupRef);
      }
    }, [groupRef.current]);

    return (
      <Group className={className} ref={groupRef}>
        {!!label && <Label variant="body2">{label}</Label>}
        <Grid {...gridProps}>{children}</Grid>
      </Group>
    );
  }
);
