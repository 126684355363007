import React, { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { FieldInputProps, FieldRenderProps } from 'react-final-form';
import { InputProps } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import { TooltipIconButton } from '@keaze/web/components/tooltipIconButton';
import { Checkbox } from '@keaze/web/components/checkbox';
import { TextField } from '@keaze/web/components/textField';
import { Select, SelectOption } from '@keaze/web/components/select';
import {
  ChangeFieldEvent,
  Components,
  FieldProps,
  Input,
  OnTextFieldKeyDown,
} from './field.types';
import {
  Field as UIField,
  InputAdornment,
  StyledTextField,
} from './field.styles';
import { OnChange, CommonProps } from './field.types';

const NOT_NUMBERS_REG = /\D+/g;

interface Props<T> extends FieldRenderProps<T, HTMLElement> {
  fieldProps: FieldProps;
}

type RenderEndAdornment = (hint: string) => JSX.Element;

type ComponentFactoryArgs<T> = {
  input: Input<T>;
  commonProps: CommonProps;
  selectData: SelectOption[];
  multiline: boolean;
  inputProps: Partial<InputProps>;
  placeholder: string;
  onChange: OnChange;
};

type ExpectedComponentFactory = {
  [Components.Select]: () => JSX.Element;
  [Components.Checkbox]: () => JSX.Element;
  [Components.TextField]: () => JSX.Element;
  [Components.DatePicker]: () => JSX.Element;
};

type GenerateInputPropsArgs = {
  hint?: string;
  adornment?: string;
};

type GenerateInputProps = (args: GenerateInputPropsArgs) => Partial<InputProps>;

type GenerateTypeValue = (
  e: ChangeFieldEvent,
  input: FieldInputProps<unknown, HTMLElement>
) => void;

const DatePickerIcon = memo(() => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 4V6H15V4H18V8H2V4H5V6H7V4H13ZM0 9V4V2H2H5V0H7V2H13V0H15V2H18H20V4V9V19V21H18H2H0V19V9ZM18 10V19H2V10H18Z"
      fill="#EC6779"
    />
  </svg>
));

const onTextFieldKeyDown: OnTextFieldKeyDown = (e, type = 'text') => {
  if (type === 'number') {
    if (
      e.code === 'KeyE' ||
      e.code === 'Minus' ||
      e.code === 'Equal' ||
      e.code === 'Comma' ||
      e.code === 'Period'
    ) {
      e.preventDefault();
    }
  }
};

const generateInputProps: GenerateInputProps = ({ hint, adornment }) => {
  let inputProps = {};

  if (hint) {
    inputProps = {
      ...inputProps,
      endAdornment: renderEndAdornment(hint),
    };
  }

  if (adornment) {
    inputProps = {
      ...inputProps,
      startAdornment: (
        <InputAdornment position="start">
          <Typography variant="body1">{adornment}</Typography>
        </InputAdornment>
      ),
    };
  }

  return inputProps;
};

const renderDatePicker = <T extends unknown>(
  input: Input<T>,
  commonProps: CommonProps
): JSX.Element => (
  <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <KeyboardDatePicker
      {...(input as Input<any>)}
      {...commonProps}
      variant="inline"
      inputVariant="outlined"
      format="dd.MM.yyyy"
      placeholder="DD.MM.YYYY"
      TextFieldComponent={TextField}
      inputProps={{
        inputMode: 'numeric',
      }}
      keyboardIcon={<DatePickerIcon />}
      KeyboardButtonProps={{
        'aria-label': 'change date',
      }}
    />
  </MuiPickersUtilsProvider>
);

const componentFactory = <T extends unknown>({
  input,
  commonProps,
  multiline,
  inputProps,
  placeholder,
  selectData,
  onChange,
}: ComponentFactoryArgs<T>): ExpectedComponentFactory => ({
  [Components.Select]: () => {
    const { value, ...restInput } = input;

    return (
      <Select
        {...restInput}
        {...commonProps}
        value={value ?? ''}
        data={selectData}
        onChange={(e) => onChange(e as ChangeFieldEvent)}
      />
    );
  },
  [Components.Checkbox]: () => (
    <Checkbox
      {...input}
      {...commonProps}
      label={commonProps.label}
      onChange={onChange}
    />
  ),
  [Components.TextField]: () => {
    const { value, ...restInput } = input;

    return (
      <StyledTextField
        {...restInput}
        {...commonProps}
        $isValue={!!input.value}
        value={value ?? ''}
        placeholder={placeholder}
        multiline={multiline}
        InputProps={inputProps}
        onChange={onChange}
        onKeyDown={(e) => onTextFieldKeyDown(e, input.type)}
      />
    );
  },
  [Components.DatePicker]: () => renderDatePicker<T>(input, commonProps),
});

const renderEndAdornment: RenderEndAdornment = (hint) => (
  <InputAdornment position="end">
    <TooltipIconButton texts={[hint]} />
  </InputAdornment>
);

const generateTypeNumberValue: GenerateTypeValue = ({ target }, input) => {
  const value = target.value.replace(NOT_NUMBERS_REG, '');

  if (value.length !== 0 || target.validity.valid || target.value === '') {
    return input.onChange(value);
  }
};

const FieldInner = <T extends unknown>({
  fieldProps: {
    label = '',
    hint,
    adornment,
    type = '',
    placeholder = '',
    multiline = false,
    disabled = false,
    selectData = [],
    component = Components.TextField,
    onChange,
  },
  input,
  meta,
  getRef,
}: Props<T>): JSX.Element => {
  const fieldRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (getRef && fieldRef.current) {
      getRef(input.name, fieldRef.current);
    }
  }, [fieldRef.current]);

  const handleChange: OnChange = useCallback(
    (e) => {
      if (onChange) {
        return onChange(e, input.onChange);
      }

      if (type === 'number') {
        return generateTypeNumberValue(e, input);
      }

      if (type === 'checkbox') {
        input.onChange(e);
        input.onBlur();
        return;
      }

      input.onChange(e);
    },
    [type]
  );

  const commonProps: CommonProps = {
    label,
    error:
      (!!meta.error && !!meta.touched) ||
      (!!meta.error && !!meta.submitSucceeded),
    helperText: meta.error,
    disabled,
  };

  const inputProps = useMemo(() => generateInputProps({ hint, adornment }), [
    hint,
    adornment,
  ]);

  const factory = componentFactory<T>({
    input,
    commonProps,
    multiline,
    inputProps,
    placeholder,
    selectData,
    onChange: handleChange,
  })[component];

  return <UIField ref={fieldRef}>{factory()}</UIField>;
};

export const Field = memo(FieldInner);
