import { ChangeEvent, memo, useCallback } from 'react';
import { FieldRenderProps } from 'react-final-form';
import MUIRadio from '@material-ui/core/Radio';

import {
  FormControlLabel,
  Icon,
  Radio as UIRadio,
  ErrorMessage,
  FormControl,
} from './radio.styles';

type OnChange = (
  event: ChangeEvent<HTMLInputElement>,
  checked: boolean
) => void;

const CheckIcon = () => (
  <svg width="18" height="13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.442.287a1 1 0 01.012 1.414l-10.822 11a1 1 0 01-1.426 0l-4.919-5A1 1 0 011.713 6.3l4.206 4.275L16.03.3a1 1 0 011.413-.012z"
    />
  </svg>
);

const CloseIcon = () => (
  <svg width="13" height="12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M.706 11.657a1 1 0 001.415 0l4.243-4.243 4.242 4.242a1 1 0 001.415-1.414L7.778 6l4.242-4.243A1 1 0 0010.606.343L6.364 4.585 2.12.343A1 1 0 00.707 1.757L4.95 6 .706 10.243a1 1 0 000 1.414z" />
  </svg>
);

export const RadioInner = <T extends unknown>({
  fieldProps: { type, onChange },
  label,
  input,
  meta,
}: FieldRenderProps<T, HTMLElement>): JSX.Element => {
  const yes = input.value === 'yes';
  const error = !!meta.error && !!meta.touched;
  const visibleError = yes && error;
  const icon = yes ? <CheckIcon /> : <CloseIcon />;

  const handleChange: OnChange = useCallback(
    (e) => {
      if (onChange) {
        return onChange(e, input.onChange);
      }

      input.onChange(e);
    },
    [type]
  );

  return (
    <UIRadio>
      <FormControl component="fieldset">
        <FormControlLabel
          $checked={input.checked}
          $error={error}
          label={label}
          control={<MUIRadio {...input} onChange={handleChange} />}
        />
        <Icon $checked={input.checked} $yes={yes}>
          {icon}
        </Icon>
      </FormControl>
      {visibleError && (
        <ErrorMessage variant="body2">{meta.error}</ErrorMessage>
      )}
    </UIRadio>
  );
};

export const Radio = memo(RadioInner);
