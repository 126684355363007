import { ChangeEvent, ReactNode, KeyboardEvent } from 'react';
import { FieldInputProps } from 'react-final-form';

import { SelectOption } from '@keaze/web/components/select';
import { CheckboxProps } from '../checkboxField';
import { Validate } from '../fields.types';

export enum Components {
  TextField = 'text-field',
  Checkbox = 'checkbox',
  CheckboxGroup = 'checkbox-group',
  Select = 'select',
  DatePicker = 'date-picker',
  Radio = 'radio',
  Button = 'button',
}

export type OnChangeField = (
  e: ChangeFieldEvent,
  onChangeInput: (e: any) => void
) => void;

export type FieldProps = {
  label?: ReactNode;
  name: string;
  type?: string;
  hint?: string;
  placeholder?: string;
  multiline?: boolean;
  component?: Components;
  selectData?: SelectOption[];
  checkboxGroupData?: CheckboxProps[];
  validate?: Validate;
  adornment?: any;
  disabled?: boolean;
  onChange?: OnChangeField;
  onClick?: any;
};

export type ChangeFieldEvent = ChangeEvent<
  HTMLTextAreaElement | HTMLInputElement
>;

export type OnSelectChange = (
  event: ChangeEvent<{
    name?: string | undefined;
    value: unknown;
  }>,
  child: ReactNode,
  onChangeInput: (e: any) => void
) => void;
export type OnChange = (e: ChangeFieldEvent) => void;

export type Input<T> = FieldInputProps<T, HTMLElement>;

export type CommonProps = {
  label: ReactNode;
  error: boolean;
  helperText: string;
  disabled: boolean;
};

export type OnTextFieldKeyDown = (
  e: KeyboardEvent<HTMLDivElement>,
  type?: string
) => void;
