import { useMemo } from 'react';
import { useField, useForm } from 'react-final-form';

import {
  FieldProps,
  Components,
  ResetFieldsValue,
  resetFields,
} from '@keaze/web/utils/form';
import { List } from '@keaze/web/common/interfaces';
import { SelectOption } from '@keaze/web/components/select';
import { FormField } from './homeOwnershipStatuses.types';

const SELLING_RESET_VALUE: ResetFieldsValue = {
  name: FormField.SellingStatus,
  value: null,
};

const PROPERTY_RESET_VALUE: ResetFieldsValue = {
  name: FormField.PropertyStatus,
  value: null,
};

const FIRST_TIME_BUYER_RESET_VALUE: ResetFieldsValue = {
  name: FormField.FirstTimeBuyerStatus,
  value: null,
};

type UseHomeOwnershipStatusesFieldsExpected = {
  fields: FieldProps[];
};

type UseHomeOwnershipStatusesFields = (
  list: List,
  selectData: SelectOption[]
) => UseHomeOwnershipStatusesFieldsExpected;

type GenerateAdditionalFieldsArgs = {
  isOwner?: boolean;
  firstTimeBuyerStatusValue: string;
  propertyStatusValue: string;
  sellingField: FieldProps;
  propertyField: FieldProps;
  firstTimeBuyer: FieldProps;
};

type GenerateAdditionalFields = (
  args: GenerateAdditionalFieldsArgs
) => FieldProps[];

const generateAdditionalFields: GenerateAdditionalFields = ({
  isOwner,
  firstTimeBuyerStatusValue,
  propertyStatusValue,
  sellingField,
  propertyField,
  firstTimeBuyer,
}) => {
  let fields: FieldProps[] = [];

  if (isOwner) {
    return [sellingField];
  }

  if (firstTimeBuyerStatusValue === 'no') {
    fields = [...fields, propertyField];
  }

  if (propertyStatusValue === 'yes') {
    fields = [...fields, sellingField];
  }

  return [firstTimeBuyer, ...fields];
};

export const useHomeOwnershipStatusesFields: UseHomeOwnershipStatusesFields = (
  list,
  selectData
) => {
  const form = useForm();
  const {
    input: { value: livingCircumstanceValue },
  } = useField(FormField.LivingCircumstance);
  const {
    input: { value: firstTimeBuyerStatusValue },
  } = useField(FormField.FirstTimeBuyerStatus);
  const {
    input: { value: propertyStatusValue },
  } = useField(FormField.PropertyStatus);

  const selected = useMemo(
    () => list.find(({ id }) => id == livingCircumstanceValue),
    [list, livingCircumstanceValue]
  );
  const isOwner = useMemo(() => selected && selected.owner, [selected]);

  const sellingField: FieldProps = {
    label: 'Are you selling or planning on selling?',
    name: FormField.SellingStatus,
    type: 'radio',
    component: Components.Radio,
    validate: {
      required: false,
    },
  };

  const propertyField: FieldProps = {
    label: 'Do you own any other property at the moment?',
    name: FormField.PropertyStatus,
    type: 'radio',
    component: Components.Radio,
    validate: {
      required: false,
    },
    onChange: ({ target: { value } }, onChangeInput) => {
      resetFields<FormField>({
        form,
        values: [SELLING_RESET_VALUE],
      });

      return onChangeInput(value);
    },
  };

  const firstTimeBuyer: FieldProps = {
    label: 'Are you a first time buyer?',
    name: FormField.FirstTimeBuyerStatus,
    type: 'radio',
    component: Components.Radio,
    validate: {
      required: false,
    },
    onChange: ({ target: { value } }, onChangeInput) => {
      resetFields<FormField>({
        form,
        values: [PROPERTY_RESET_VALUE, SELLING_RESET_VALUE],
      });

      return onChangeInput(value);
    },
  };

  const additionalFields = livingCircumstanceValue
    ? generateAdditionalFields({
        isOwner,
        firstTimeBuyerStatusValue,
        propertyStatusValue,
        sellingField,
        propertyField,
        firstTimeBuyer,
      })
    : [];

  return {
    fields: [
      {
        label: 'Current living situation',
        name: FormField.LivingCircumstance,
        component: Components.Select,
        selectData,
        validate: {
          required: false,
        },
        onChange: ({ target: { value } }, onChangeInput) => {
          resetFields<FormField>({
            form,
            values: [
              FIRST_TIME_BUYER_RESET_VALUE,
              PROPERTY_RESET_VALUE,
              SELLING_RESET_VALUE,
            ],
          });

          return onChangeInput(value);
        },
      },
      ...additionalFields,
    ],
  };
};
