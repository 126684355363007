import { useCallback } from 'react';
import { useForm } from 'react-final-form';

import { FormFieldRefs } from '../form.types';

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const useScrollToField = (fieldRefs: FormFieldRefs) => {
  const form = useForm();

  const scrollToField = useCallback(async () => {
    await sleep(300);

    const formState = form.getState();
    const { submitSucceeded, submitFailed, errors = {} } = formState;
    const isErrors = Object.keys(errors).length !== 0;

    if ((submitFailed && isErrors) || (submitSucceeded && isErrors)) {
      const firstErrorName = Object.keys(errors)[0];
      const fieldRef = fieldRefs[firstErrorName];
      if (fieldRef) {
        fieldRef.scrollIntoView({
          behavior: 'smooth',
        });
      }
    }
  }, [fieldRefs]);

  return {
    scrollToField,
  };
};
