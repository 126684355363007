import {
  Dictionary,
  FlatForm,
  EmailValidationError,
  PhoneValidationError,
} from '@keaze/web/common/interfaces';
import { OnChangeField } from '@keaze/web/utils/form';

const EMAIL_EN_REG = /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/;
const PHONE_REG = /^[+]?[\d-()\s]{10,20}$/;
const ONLY_DIGITS_REG = /^\d+$/;

type IsEmpty = (value?: string | null) => boolean;

export const required = (value: any) => (value ? null : 'Required');

export const integer = (value: string | number) =>
  value && parseInt(value as string) != value ? 'Must be an integer' : null;

export const maxDigits = (cnt: number) => (value: string | number) =>
  value && (value as string).length > cnt
    ? `Must be an integer with no more than ${cnt} digits`
    : null;

export const minLength = (min: number) => (value: any[]) =>
  value && value.length < min
    ? `Must contain a minimum of ${min} characters`
    : null;
export const emailFormat = (value: string) =>
  value &&
  !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    value
  )
    ? 'Invalid email address'
    : null;

export const maxLength = (max: number) => (value: any[]) =>
  value && value.length > max
    ? `Must not contain more than ${max} characters`
    : null;

export const validateNumber = (value: string | number) =>
  value && parseFloat(value as string) < 0 ? 'Must be positive number' : null;

export const validRadio = (value: string) =>
  value && !['yes', 'no'].includes(value) ? 'Must answer yes or no' : null;

export const zipValidation = (value: string) =>
  value && !/^\d{5}([-]|\s*)?(\d{4})?$/.test(value);
export const pathValidation = (value: string) =>
  value && !/^[a-zA-Z0-9\-_]+$/.test(value);

export const containsDigits = (cnt: number) => (value: string) =>
  value && !new RegExp(`(.*[0-9]){${cnt},}`).test(value)
    ? `Should Contain at least ${cnt} Digits`
    : null;
export const containsSpecials = (cnt: string) => (value: string) =>
  value && !new RegExp(`(.*[^a-zA-Z0-9]){${cnt},}`).test(value)
    ? `Should Contain at least ${cnt} Specials characters`
    : null;
export const containsCapitals = (cnt: string) => (value: string) =>
  value && !new RegExp(`(.*[A-Z]){${cnt},}`).test(value)
    ? `Should Contain at least ${cnt} Capitals letters`
    : null;

export const chain = (...fns: Array<(...args: any) => any>) => (value: any) => {
  let result: any;
  fns.forEach((validator) => (result = result || validator(value)));
  return result || false;
};

// export const strongPassword = chain(minLength(6), containsDigits(2), containsSpecials(2), containsCapitals(2));
export const strongPassword = chain(minLength(6));

export const equal = (firstValue: string | number) => (
  secondValue: string | number
) => (firstValue === secondValue ? null : 'Passwords do not match');

export const dayMonthValidation = (value: {
  day?: string;
  month?: string;
}): { day?: string; month?: string } => {
  if (value.month && !value.day) {
    return { day: 'Choose a day' };
  }
  if (value.day && !value.month) {
    return { month: 'Choose a month' };
  }
  return {};
};

export const multipleChoices = (
  values: Dictionary<any>,
  id: number | string,
  min: number
) => {
  let errors = {};
  const question3 = values[id];
  if (question3) {
    const formValues = values.my_values;
    const selected = Object.keys(formValues).filter((k) => formValues[k]);
    const length = selected.length;
    if (length < min) {
      errors = { ...errors, formError: `Please choose at least ${min} option` };
    }
  } else {
    errors = { ...errors, formError: `Please choose at least ${min} option` };
  }
  return errors;
};

export const emailValidationError: EmailValidationError = (value) =>
  !value || EMAIL_EN_REG.test(value) ? null : 'Invalid email';

export const phoneValidationError: PhoneValidationError = (value) =>
  !value || PHONE_REG.test(value) ? null : 'Invalid phone number';

export const validatePostCode = (postcode: string) =>
  !postcode ||
  /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z]))))\s?[0-9][A-Za-z]{2})$/i.test(
    postcode
  )
    ? null
    : 'Incorrect postcode';

export const checkFormFilled = (
  form: FlatForm,
  requiredFieldNames: string[] = Object.keys(form)
): boolean =>
  requiredFieldNames.reduce(
    (isFilled: boolean, fieldName: string) =>
      isFilled && Array.isArray(form[fieldName])
        ? (form[fieldName] as (string | number)[]).length > 0
        : !!form[fieldName],
    true
  );

export const isDigit = (value: string): boolean => ONLY_DIGITS_REG.test(value);

export const toUppercase: OnChangeField = (
  { target: { value } },
  onChangeInput
) => onChangeInput(value.toUpperCase());

export const isEmpty: IsEmpty = (value) =>
  value === undefined || value === null || value.length === 0;
