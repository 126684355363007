import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { fade } from '@material-ui/core/styles';

export const Group: any = styled(Box)`
  margin-bottom: 5px;
`;

export const Label = styled(Typography)`
  ${({ theme: { colors } }) => `
    color: ${fade(colors.greys.greys6, 0.75)};
  `}
`;
