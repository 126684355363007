import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

export const Group = styled(Box)`
  margin-bottom: 40px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Title = styled(Typography)`
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 18px;
`;

export const Content = styled(Box)``;
