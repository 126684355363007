import { SelectOption } from '@keaze/web/components/select';
import { List } from '@keaze/web/common/interfaces';
import { Lists, ListsForSelect } from './lists.types';

export const TITLE_SELECT_DATA: SelectOption[] = [
  {
    label: 'Mr',
    value: 'Mr',
  },
  {
    label: 'Mrs',
    value: 'Mrs',
  },
  {
    label: 'Miss',
    value: 'Miss',
  },
  {
    label: 'Ms',
    value: 'Ms',
  },
  {
    label: 'Mx',
    value: 'Mx',
  },
  {
    label: 'Dr',
    value: 'Dr',
  },
  {
    label: 'Sir',
    value: 'Sir',
  },
  {
    label: 'Other',
    value: ' ',
  },
];

type ConvertListToSelectOption = (list: List) => SelectOption[];

type GenerateListsForSelect = (lists: Lists) => ListsForSelect;

export const convertListToSelectOption: ConvertListToSelectOption = (
  list = []
) => list.map(({ id, name }) => ({ label: name, value: id }));

export const generateListsForSelect: GenerateListsForSelect = (lists) =>
  Object.keys(lists).reduce(
    (acc, key) => ({ ...acc, [key]: convertListToSelectOption(lists[key]) }),
    {} as ListsForSelect
  );
